<template>
    <dashboard-layout>

        <div class="content-area__body">
            <!-- settings section -->
            <section class="settings-section section-gap-30">

                <SettingMenu></SettingMenu>

                <div class="setting-content">
                    <div class="section-title-wrap mb-0 d-flex justify-content-between align-items-start">
                        <div class="margin-deliver">
                            <p class="section-title">{{ $t(`photos & colors`) }}</p>
                        </div>
                    </div>

                    <form @submit.prevent="">
                        <div class="form-box feature-image-box">
                            <div class="image-selector form-group">
                                <div class="company-logo">
                                    <label> {{ $t("Company Logo") }}</label>
                                    <v-popover offset="10" :disabled="false" style="display: initial">
                                        <button class="ej__tooltip__button ml-1"><i class="eicon e-question"></i>
                                        </button>
                                        <template slot="popover">
                                            <a class="tooltip-close" v-close-popover><i class="eicon e-close"></i></a>
                                            <a :href="help.logo.link" target="_blank">
                                                {{ $t(help.logo.msg) }}
                                            </a>
                                        </template>
                                    </v-popover>
                                    <div class="selection-box">
                                        <div class="img-box">
                                            <img class="img-thumbnail" :src="logoImgSrc" alt="Company Logo"/>
                                            <a href="#" class="remove-button"
                                               v-if="hasLogo"
                                               @click.prevent="removeCompanyPhoto('company_logo')">
                                                <i class="eicon e-delete"></i>
                                            </a>
                                        </div>
                                        <label class="file-browse-button">
                                            <span>{{ $t(`Change Logo`) }}</span>
                                            <input
                                                    id="image_1"
                                                    ref="input"
                                                    type="file"
                                                    accept=".png, .jpg, .jpeg"
                                                    @change="setCroppieConfig(250, 250, 400, 400); croppie('company_logo', $event)"/>
                                        </label>
                                    </div>
                                </div>
                                <div class="company-favicon">
                                    <label>{{ $t("Company Favicon") }}</label>
                                    <v-popover offset="10" :disabled="false" style="display: initial">
                                        <button class="ej__tooltip__button ml-1"><i class="eicon e-question"></i>
                                        </button>
                                        <template slot="popover">
                                            <a class="tooltip-close" v-close-popover><i class="eicon e-close"></i></a>
                                            <a v-close-popover :href="help.favicon.link" target="_blank">
                                                {{ $t(help.favicon.msg) }}
                                            </a>
                                        </template>
                                    </v-popover>
                                    <div class="selection-box">
                                        <div class="img-box" style="width: 65px; height: 65px">
                                            <img class="img-thumbnail" :src="faviconSrc" alt="Change favicon"/>
                                            <a href="#" class="remove-button"
                                               v-if="hasFavicon"
                                               @click.prevent="removeCompanyPhoto('company_favicon')">
                                                <i class="eicon e-delete"></i>
                                            </a>
                                        </div>
                                        <label class="file-browse-button">
                                            <span>{{ $t(`Change Favicon`) }}</span>
                                            <input
                                                    id="image_2"
                                                    ref="input"
                                                    type="file"
                                                    accept=".png, .jpg, .jpeg"
                                                    @change="setCroppieConfig(64, 64, 150, 150); croppie('company_favicon', $event)"/>
                                        </label>
                                    </div>
                                </div>
                                <div class="company-brand_color">
                                    <label>{{ $t("Brand Color") }}</label>
                                    <v-popover offset="10" :disabled="false" style="display: initial">
                                        <button class="ej__tooltip__button ml-1"><i class="eicon e-question"></i>
                                        </button>
                                        <template v-close-popover slot="popover">
                                            <a class="tooltip-close" v-close-popover><i class="eicon e-close"></i></a>
                                            <a :href="help.brandColor.link" target="_blank">
                                                {{ $t(help.brandColor.msg) }}
                                            </a>
                                        </template>
                                    </v-popover>
                                    <div class="selection-box">
                                        <v-swatches v-model="brand_color" @close="changeBrandColor" show-fallback :swatches="brandColors" fallback-input-type="color"></v-swatches>
                                    </div>
                                </div>
                                <div class="company-powered_by" v-if="isProUser">
                                    <label>{{ $t("Remove Powered By") }}</label>
                                    <v-popover offset="10" :disabled="false" style="display: initial">
                                        <button class="ej__tooltip__button ml-1"><i class="eicon e-question"></i>
                                        </button>
                                        <template v-close-popover slot="popover">
                                            <a class="tooltip-close" v-close-popover><i class="eicon e-close"></i></a>
                                            <a :href="help.removePoweredBy.link" target="_blank">
                                                {{ $t(help.removePoweredBy.msg) }}
                                            </a>
                                        </template>
                                    </v-popover>
                                    <div class="selection-box">
                                        <input type="checkbox" class="notify"
                                               v-model="removePoweredBy"
                                               value="true"
                                               @change="toggleRemovePoweredBy"
                                               id="remove-powered_by"
                                        />
                                        <label for="remove-powered_by" class="power-by__remove">
                                            <span v-if="removePoweredBy">{{ $t('yes') }}</span>
                                            <span v-else>{{ $t('no') }}</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-box mt-30 feature-image-box">
                            <p class="section-header">{{ $t(`Cover photo`) }}</p>
                            <div class="banner-upload mt-30 mb-4" v-lazy:background-image="newImage">
                                <label class="file-browse-button semi-button primary-button">
                                    <span>{{ $t(`Change Cover Photo`) }}</span>
                                    <input
                                            id="image_3"
                                            ref="cover_photo"
                                            type="file"
                                            accept=".jpg, .jpeg, .png"
                                            @change="setCroppieConfig(600, 315, 1600, 840); croppie('cover_photo', $event)"/>
                                    <v-popover hideOnTargetClick="true" offset="10" :disabled="false"
                                               class="cover-photo-help">
                                        <button class="ej__tooltip__button ml-1"><i class="eicon e-question"></i>
                                        </button>
                                        <template v-close-popover slot="popover">
                                            <a class="tooltip-close" v-close-popover><i class="eicon e-close"></i></a>
                                            <a :href="help.coverPhoto.link" target="_blank">
                                                {{ $t(help.coverPhoto.msg) }}
                                            </a>
                                        </template>
                                    </v-popover>
                                </label>

                                <a href="#" class="remove-button"
                                   v-if="hasCoverPhoto"
                                   :title="$t('Remove')"
                                   @click.prevent="removeCompanyPhoto('cover_photo')">
                                    <i class="eicon e-delete"></i>
                                </a>
                                <span class="company-powered_by" id="remove-company-cover-photo" v-if="isProUser">
                                        <span class="selection-box">
                                            <input type="checkbox" class="notify-custom notify-custom-inverse"
                                                   v-model="removeCoverPhoto"
                                                   value="true"
                                                   @change="toggleRemoveCoverPhoto"
                                                   id="remove-cover-photo"
                                            />
                                            <label for="remove-cover-photo" class="power-by__remove">
                                                <span style="text-transform: none;"
                                                      v-if="removeCoverPhoto">{{ $t('Hide') }}</span>
                                                <span style="text-transform: none;" v-else>{{ $t('Show') }}</span>
                                            </label>
                                        </span>
                                    </span>
                            </div>
                            <div class="d-flex justify-content-between">
                                <label>
                                    <h6 class="d-inline">{{ $t(`Life at`) }} {{ companyFromState.name }}</h6>
                                    <v-popover offset="10" :disabled="false" style="display: initial">
                                        <button class="ej__tooltip__button ml-1"><i class="eicon e-question"></i>
                                        </button>
                                        <template slot="popover">
                                            <a class="tooltip-close" v-close-popover><i class="eicon e-close"></i></a>
                                            <a v-close-popover :href="help.lifeAt.link" target="_blank">
                                                {{ $t(help.lifeAt.msg) }}
                                            </a>
                                        </template>
                                    </v-popover>
                                </label>
                                <label class="checkbox">
                                    <input type="checkbox" @change="saveShowCompanyLife" v-model="showCompanyLife"/>
                                    <span>{{ $t(`Show on Company Page`) }}</span>
                                </label>
                            </div>
                            <div class="form-group">
                                <label>{{ $t(`Upload Images to showcase your team & company.`) }}</label>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="feature-image feature-image-lg"
                                         v-lazy:background-image="showcasePhoto1">
                                        <label class="file-browse-button semi-button primary-button">
                                            <span>{{ $t(`Change Photo`) }}</span>
                                            <input
                                                    id="image_4"
                                                    ref="input"
                                                    type="file"
                                                    accept=".jpg, .jpeg, .png"
                                                    @change="setCroppieConfig(480, 350, 720, 525); croppie('showcase_photo_1', $event)"/>
                                        </label>
                                        <a href="#" class="remove-button"
                                           v-if="hasShowcasePhoto[1]"
                                           :title="$t('Remove')"
                                           @click.prevent="removeCompanyPhoto('showcase_photo', 1)">
                                            <i class="eicon e-delete"></i>
                                        </a>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="feature-image feature-image-md"
                                                 v-lazy:background-image="showcasePhoto2">
                                                <label class="file-browse-button semi-button primary-button">
                                                    <span>{{ $t(`Change Photo`) }}</span>
                                                    <input
                                                            id="image_5"
                                                            ref="input"
                                                            type="file"
                                                            accept=".jpg, .jpeg, .png"
                                                            @change="setCroppieConfig(480, 350, 720, 525); croppie('showcase_photo_2', $event)"/>
                                                </label>
                                                <a href="#" class="remove-button"
                                                   v-if="hasShowcasePhoto[2]"
                                                   :title="$t('Remove')"
                                                   @click.prevent="removeCompanyPhoto('showcase_photo', 2)">
                                                    <i class="eicon e-delete"></i>
                                                </a>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="feature-image feature-image-md"
                                                 v-lazy:background-image="showcasePhoto3">
                                                <label class="file-browse-button semi-button primary-button">
                                                    <span>{{ $t(`Change Photo`) }}</span>
                                                    <input
                                                            id="image_6"
                                                            ref="input"
                                                            type="file"
                                                            accept=".jpg, .jpeg, .png"
                                                            @change="setCroppieConfig(480, 350, 720, 525); croppie('showcase_photo_3', $event)"/>
                                                </label>
                                                <a href="#" class="remove-button"
                                                   v-if="hasShowcasePhoto[3]"
                                                   :title="$t('Remove')"
                                                   @click.prevent="removeCompanyPhoto('showcase_photo', 3)">
                                                    <i class="eicon e-delete"></i>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mt-30">
                                        <div class="col-md-6">
                                            <div class="feature-image feature-image-md"
                                                 v-lazy:background-image="showcasePhoto4">
                                                <label class="file-browse-button semi-button primary-button">
                                                    <span>{{ $t(`Change Photo`) }}</span>
                                                    <input
                                                            id="image_7"
                                                            ref="input"
                                                            type="file"
                                                            accept=".jpg, .jpeg, .png"
                                                            @change="setCroppieConfig(480, 350, 720, 525); croppie('showcase_photo_4', $event)"/>
                                                </label>
                                                <a href="#" class="remove-button"
                                                   v-if="hasShowcasePhoto[4]"
                                                   :title="$t('Remove')"
                                                   @click.prevent="removeCompanyPhoto('showcase_photo', 4)">
                                                    <i class="eicon e-delete"></i>
                                                </a>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="feature-image feature-image-md"
                                                 v-lazy:background-image="showcasePhoto5">
                                                <label class="file-browse-button semi-button primary-button">
                                                    <span>{{ $t(`Change Photo`) }}</span>
                                                    <input
                                                            id="image_8"
                                                            ref="input"
                                                            type="file"
                                                            accept=".jpg, .jpeg, .png"
                                                            @change="setCroppieConfig(480, 350, 720, 525); croppie('showcase_photo_5', $event)"/>
                                                </label>
                                                <a href="#" class="remove-button"
                                                   v-if="hasShowcasePhoto[5]"
                                                   :title="$t('Remove')"
                                                   @click.prevent="removeCompanyPhoto('showcase_photo', 5)">
                                                    <i class="eicon e-delete"></i>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </section>
        </div>

        <div v-if="showCropperModel" class="modal vue-cropper show" id="vue-cropper-modal" style="display: block">
            <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div class="modal-content" v-on-click-away="closeCropperModal">
                    <div class="modal-header">
                        <h5 class="modal-title">{{ $t(`Resize Image`) }}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true" @click="closeCropperModal">&times;</span>
                        </button>
                    </div>
                    <div id="image-crop-wrapper" class="modal-body croppie-container ready">
                        <vue-croppie
                                ref="croppieRef"
                                :enableOrientation="true"
                                :enableResize="false"
                                :boundary="croppieShowOption.boundary"
                                :viewport="croppieShowOption.viewport">
                        </vue-croppie>
                    </div>

                    <div class="modal-footer flex justify-content-between">
                        <button type="button" class="button warning-button" @click="closeCropperModal">{{ $t(`Close`) }}
                        </button>
                        <button type="button" @click.prevent="cropImage()" class="button success-button"
                                :class="{'disabled' : uploadingImage}">
                            <span v-if="uploadingImage">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                     class="mr-2" style="margin: auto; shape-rendering: auto;" width="20px"
                                     height="20px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                                    <circle cx="50" cy="50" r="24" stroke-width="8" stroke="#fff"
                                            stroke-dasharray="37.69911184307752 37.69911184307752" fill="none"
                                            stroke-linecap="round" transform="rotate(300.791 50 50)">
                                        <animateTransform attributeName="transform" type="rotate"
                                                          repeatCount="indefinite" dur="1.01010101010101s"
                                                          keyTimes="0;1" values="0 50 50;360 50 50"></animateTransform>
                                    </circle>
                                </svg>
                                {{ $t("please wait") }}
                            </span>
                            <template v-else>{{ $t(`Save`) }}</template>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="showCropperModel" class="modal-backdrop fade show"></div>
    </dashboard-layout>
</template>
<script>
import Vue from 'vue';
import VSwatches from 'vue-swatches'
import 'vue-swatches/dist/vue-swatches.css'

const DashboardLayout = () => import("../../../layouts/DashboardLayout");
const SettingMenu = () => import("../../../components/company/SettingSidebar");


import client from "../../../app/api/Client";
import {mapState, mapActions, mapMutations} from 'vuex';
import {UPDATE_COMPANY_DATA} from "../../../constants/action-type";
import VueCroppie from "vue-croppie";
import 'croppie/croppie.css';
import VueLazyLoad from "vue-lazyload";
import {vueJsLazyLoad as vueJsLazyLoadOptions} from "../../../config/options";
import {SET_STATE_VERSION} from "../../../constants/mutations-types";


Vue.use(VueLazyLoad, vueJsLazyLoadOptions);

Vue.use(VueCroppie);


export default {
    components: {
        DashboardLayout,
        SettingMenu,
        VSwatches
    },
    data() {
        return {
            showCompanyLife: false,
            companyFromState: {name: ''},
            showCropperModel: false,
            coverPhoto: '',
            showcasePhoto1: '',
            showcasePhoto2: '',
            showcasePhoto3: '',
            showcasePhoto4: '',
            showcasePhoto5: '',
            hasCoverPhoto: false,
            hasShowcasePhoto: {
                1: false,
                2: false,
                3: false,
                4: false,
                5: false
            },
            tempfile: null,
            uploadingImage: false,
            croppieUploadOption: {
                enableExif: true,
                quality: 1,
                // format: 'jpeg',
                size: {
                    width: null,
                    height: null
                },
            },
            croppieShowOption: {
                boundary: {
                    width: null,
                    height: null,
                },
                viewport: {
                    width: null,
                    height: null,
                }
            },
            logoImgSrc: "/assets/images/placeholder/company_150X150.jpeg",
            faviconSrc: '/favicon.png',
            hasLogo: false,
            hasFavicon: false,
            brandColors: ["#597dfc", "#60ce83", "#ff5f74", "#2fc1e1", "#ff9635"],
            brand_color: null,
            default_brand_color: null,
            help: {
                logo: {
                    link: 'https://easy.jobs/docs/how-to-change-company-logo/',
                    msg: 'How to change company logo?',
                },
                favicon: {
                    link: 'https://easy.jobs/docs/change-favicon-career-site/',
                    msg: 'How to change the favicon?',
                },
                brandColor: {
                    msg: 'How to set desired brand colors?',
                    link: 'https://easy.jobs/docs/change-brand-colors',
                },
                removePoweredBy: {
                    msg: 'How to remove ‘powered by easy.jobs’ text?',
                    link: 'https://easy.jobs/docs/remove-powered-by-watermark',
                },
                coverPhoto: {
                    msg: 'How to change the cover photo?',
                    link: 'https://easy.jobs/docs/change-cover-photo/',
                },
                lifeAt: {
                    msg: 'How to Add Team Photo?',
                    link: 'https://easy.jobs/docs/add-team-photo-career-site/',
                },
                removeCoverPhoto: {
                    msg: 'How to remove ‘powered by easy.jobs’ text?',
                    link: 'https://easy.jobs/docs/remove-powered-by-watermark',
                }
            },
            removePoweredBy: false,
            removeCoverPhoto: false,
        }
    },

    computed: {
        ...mapState(['company', 'user']),
        newImage() {
            if (this.coverPhoto == '') {
                return '/assets/images/placeholder/loader.svg';
            } else {
                return this.coverPhoto;
            }
        },

        isProUser() {
            return this.user.package_rules.name !== 'Free';
        }

    },

    methods: {
        ...mapActions([UPDATE_COMPANY_DATA]),
        ...mapMutations([SET_STATE_VERSION]),

        getSettingPhoto() {
            client()
                .get("/company/setting/company-photo")
                .then(({data}) => {
                    this.logoImgSrc = data.data.logo;
                    this.faviconSrc = data.data.favicon;
                    this.coverPhoto = data.data.cover_photo;
                    this.showcasePhoto1 = data.data.showcase_photos_1;
                    this.showcasePhoto2 = data.data.showcase_photos_2;
                    this.showcasePhoto3 = data.data.showcase_photos_3;
                    this.showcasePhoto4 = data.data.showcase_photos_4;
                    this.showcasePhoto5 = data.data.showcase_photos_5;

                    this.hasLogo = data.data.hasLogo;
                    this.hasFavicon = data.data.hasFavicon;
                    this.hasCoverPhoto = data.data.hasCoverPhoto;
                    this.hasShowcasePhoto = data.data.hasShowcasePhoto;

                    this.brand_color = data.data.brand_color;
                    this.default_brand_color = data.data.brand_color;
                    this.removePoweredBy = data.data.remove_powered_by;
                    this.removeCoverPhoto = data.data.remove_cover_photo;
                    this.showCompanyLife = data.data.show_life;

                    this.generateBrandColor(this.default_brand_color);
                })
                .catch(error => {
                    return this.showErrorText(error)
                });
        },

        generateBrandColor(color) {
            this.brandColors[5] = color;
            this.brandColors = _.uniq(this.brandColors);
        },

        croppie(target, e) {
            this.showCropperModal();

            this.target = target;

            var files = e.target.files || e.dataTransfer.files;
            if (!files.length) {
                this.closeCropperModal();
                return;
            }


            this.tempfile = files[0];
            var reader = new FileReader();

            let allowedFileExists = ['png', 'jpg', 'jpeg'];

            if (!allowedFileExists.includes(this.tempfile.name.split('.').pop().toLowerCase())) {
                this.closeCropperModal();
                this.$toast.error(this.$t(`Invalid image format.`));
                return;
            }


            reader.onload = e => {
                this.$refs.croppieRef.bind({
                    url: e.target.result
                });
            };

            reader.readAsDataURL(this.tempfile);
        },

        cropImage() {

            let croppedImage = this.$refs.croppieRef;

            croppedImage.result(this.croppieUploadOption, base64 => {

                fetch(base64).then(e => e.blob()).then((blob) => {

                    const formData = new FormData();

                    formData.append('file', blob, this.target + '.' + this.tempfile.name.split(".").pop());
                    formData.append('target', this.target);

                    this.uploadingImage = true;

                    client()
                        .post('/company/setting/company-photo', formData)
                        .then(({data}) => {
                            this.setUploadedImage(this.target, base64);
                            if (this.target == 'company_logo') {
                                let company = data.data;
                                this[UPDATE_COMPANY_DATA](company);
                                this[SET_STATE_VERSION](company.state_version);
                            }
                            this.uploadingImage = false;

                            this.closeCropperModal();

                            return this.$toast.success(data.message);

                        })
                        .catch(error => {
                            return this.showErrorText(error)
                        }).finally(() =>{
                        this.uploadingImage = false;
                    });
                })
            });
        },

        setCroppieConfig(vWidth, vHeight, width, height) {

            this.croppieUploadOption.size.width = width;
            this.croppieUploadOption.size.height = height;
            this.croppieShowOption.viewport.width = vWidth;
            this.croppieShowOption.viewport.height = vHeight;

            this.croppieShowOption.boundary.width = vWidth + 100;
            this.croppieShowOption.boundary.height = vHeight + 100;

        },
        showCropperModal() {
            this.tempfile = null;
            this.target = null;
            this.showCropperModel = true;
            const el = document.body;
            el.classList.add('modal-open');
        },

        closeCropperModal() {
            this.clearAllInputFiles();
            this.showCropperModel = false;
            const el = document.body;
            this.fade = false;
            el.classList.remove('modal-open');
        },
        clearAllInputFiles() {
            document.getElementById('image_1').value = null;
            document.getElementById('image_2').value = null;
            document.getElementById('image_3').value = null;
            document.getElementById('image_4').value = null;
            document.getElementById('image_5').value = null;
            document.getElementById('image_6').value = null;
            document.getElementById('image_7').value = null;
            document.getElementById('image_8').value = null;
        },
        setUploadedImage(target, base64formate) {
            if (target === 'company_logo') {
                this.logoImgSrc = base64formate;
                this.hasLogo = true;
            } else if (target === 'company_favicon') {
                this.faviconSrc = base64formate;
                this.hasFavicon = true;
            } else if (target === 'cover_photo') {
                this.coverPhoto = base64formate;
                this.hasCoverPhoto = true;
            } else if (target === 'showcase_photo_1') {
                this.showcasePhoto1 = base64formate;
                this.hasShowcasePhoto[1] = true;
            } else if (target === 'showcase_photo_2') {
                this.showcasePhoto2 = base64formate;
                this.hasShowcasePhoto[2] = true;
            } else if (target === 'showcase_photo_3') {
                this.showcasePhoto3 = base64formate;
                this.hasShowcasePhoto[3] = true;
            } else if (target === 'showcase_photo_4') {
                this.showcasePhoto4 = base64formate;
                this.hasShowcasePhoto[4] = true;
            } else if (target === 'showcase_photo_5') {
                this.showcasePhoto5 = base64formate;
                this.hasShowcasePhoto[5] = true;
            }
        },

        saveShowCompanyLife() {

            client().post('/company/setting/show-life', {'show_life': this.showCompanyLife})
                .then(({data: {data, message}}) => {

                    this.$toast.success(this.$t(message));

                    this.errors = {};

                }).catch(error => {
                this.showErrorText(error)
            });
        },

        showErrorText(error) {
            if (typeof error == 'string') {
                return this.$toast.error(error);
            } else if (error.response !== undefined && [422, 400].includes(error.response.status)) {/* validation error */

                if (typeof error.response.data.message == 'string') {
                    return this.$toast.error(error.response.data.message ?? '');/* System error */
                }
                this.errors = error.response.data.message;
                return this.$toast.error('Oops! Please check your input');
            } else {
                // this.$toast.error('Oops! Somethings was wrong');
            }
        },

        async removeCompanyPhoto(target, order = null) {
            try {
                let confirm = await this.$dialog.confirm({
                    title: this.$t(`Confirmation`),
                    body: this.$t(`Are you sure you want to remove ` + target.split('_').join(' ') + '?')
                });

                if (!confirm) return false;

                let {data: {data: company, message}} = await client().post(`/company/setting/company-photo`, {
                    _method: 'DELETE',
                    target,
                    order
                });
                if (target === 'company_logo') {
                    this.logoImgSrc = "/assets/images/placeholder/company_150X150.jpeg";
                    this.hasLogo = false;
                    this[UPDATE_COMPANY_DATA](company);
                } else if (target === 'company_favicon') {
                    this.faviconSrc = '/favicon.png';
                    this.hasFavicon = false;
                } else {
                    this.getSettingPhoto();
                }

                this[SET_STATE_VERSION](company.state_version);
                this.$toast.success(message);
            } catch (e) {
            }
        },

        async updateBrandColor(color) {
            try {
                this.default_brand_color = color;

                let {data: {message}} = await client().post(`/company/setting/brand-color`, {
                    brand_color: color
                });

                this.generateBrandColor(color);

                this.$toast.success(this.$t(message));
            } catch (error) {
                this.brand_color = this.default_brand_color;
            }
        },
        async toggleRemovePoweredBy() {
            try {
                let {data: {data, message}} = await client().put(`/company/setting/remove-powered-by`, {
                    remove_powered_by: this.removePoweredBy
                });

                this[UPDATE_COMPANY_DATA](data);
                this.$toast.success(this.$t(message));
            } catch (error) {
                this.$toast.error(error.response.data.message);

            }
        },
        async toggleRemoveCoverPhoto() {
            try {
                let {data: {data, message}} = await client().put(`/company/setting/remove-cover-photo`, {
                    remove_cover_photo: this.removeCoverPhoto
                });
                this.$toast.success(this.$t(message));
            } catch (error) {
                this.$toast.error(error.response.data.message);
            }
        },

        changeBrandColor(color) {
            if(this.default_brand_color != color) {
                this.updateBrandColor(color);
            }
        }
    },

    beforeMount() {
        this.getSettingPhoto();

        this.companyFromState = this.company;
    }
}
</script>
<style scoped>

.form-box.feature-image-box {
    overflow: inherit !important;
}
.banner-upload,
.feature-image {
    position: relative;
}

a.remove-button {
    position: absolute;
    right: 30px;
    top: 30px;
    width: 30px;
    height: 30px;
    line-height: 32px;
    text-align: center;
    border-radius: 50%;
    color: #fff;
    background: #ff5f74;
    font-size: .9em;
}

a.remove-button:hover {
    background: #ff465e;
}

.feature-image a.remove-button {
    top: 15px;
    right: 15px;
}

.img-box {
    position: relative;
}

.img-box a.remove-button {
    position: absolute;
    right: 4px;
    color: rgba(245, 245, 245, 1);
    background: #ff5f74;
    top: 4px;
    font-size: .7em;
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    border-radius: 2px;
}

.brand--color {
    cursor: pointer;
}

.cover-photo-help {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: calc(100% + 5px);
}

input.notify[type=checkbox] {
    display: none;
}

input.notify + label {
    cursor: pointer;
    width: 64px;
    height: 30px;
    background: #FF5F74;
    display: inline-block;
    border-radius: 100px;
    position: relative;
    margin: auto;
}

input.notify + label:after {
    content: '';
    position: absolute;
    top: 5px;
    left: 5px;
    width: 20px;
    height: 20px;
    background: #fff;
    border-radius: 90px;
    transition: 0.3s;
}

input.notify:checked + label {
    background: #60CE83;
}

input.notify-inverse:checked + label {
    background: #FF5F74;

}
input.notify-inverse + label {
    background: #60CE83;
}
input.notify:checked + label:after {
    left: calc(100% - 5px);
    transform: translateX(-100%);
}

input.notify + label > span {
    color: #fff;
    text-transform: uppercase;
    float: right;
    padding: 8px 8px;
    font-size: .9rem;
    line-height: 1;
}

input.notify:checked + label > span {
    float: left;
}

.power-by__remove {
    height: 30px !important;
    margin: unset !important;
}

.company-brand_color {
    max-width: unset !important;
}

.image-selector.form-group > div {
    width: calc(25% - 20px);
    margin-right: 20px !important;
}

@media (min-width: 768px) and  (max-width: 1199px) {
    .image-selector.form-group > div {
        width: calc(50% - 30px);
        margin-right: 20px !important;
    }
}

@media all and (max-width: 1399px) {
    .form-group .company-powered_by .selection-box label:not(.checkbox):not(.radio):not(.file-browse-button):not(.brand--color) {
        height: 30px !important;
    }

    .form-box .image-selector .company-brand_color {
        margin-top: 0;
    }

    .feature-image-box {
        padding-bottom: 0;
    }

    .feature-image-box .image-selector {
        margin-bottom: 0;
    }

    .feature-image-box .image-selector > div {
        margin-bottom: 30px;
    }

    input.notify + label > span {
        padding: 9px 8px;
    }
}

@media (max-width: 767.98px) {
    .image-selector .company-favicon {
        margin-top: 0;
    }

    .image-selector .company-brand_color {
        margin-top: 0;
    }

    .image-selector.form-group > div {
        width: 100%;
        margin-right: 0 !important;
    }
}




#remove-company-cover-photo {
    position: absolute;
    top: 30px;
    right: 70px;
    display: flex;
    align-items: center;
}

#remove-company-cover-photo .selection-box {
    margin-right: 10px;
    font-size: 0;
}

input.notify-custom[type=checkbox] {
    display: none;
}

input.notify-custom + label {
    cursor: pointer;
    width: 75px;
    height: 30px;
    background: #FF5F74;
    display: inline-block;
    border-radius: 100px;
    position: relative;
    margin: auto;
}

input.notify-custom + label:after {
    content: '';
    position: absolute;
    top: 5px;
    left: calc(100% - 5px);
    transform: translateX(-100%);
    width: 20px;
    height: 20px;
    background: #fff;
    border-radius: 90px;
    transition: 0.3s;
}

input.notify-custom:checked + label {
    background: #60CE83;
}

input.notify-custom-inverse:checked + label {
    background: #FF5F74;

}
input.notify-custom-inverse + label {
    background: #60CE83;
}
input.notify-custom:checked + label:after {
    left: 5px;
    transform: translateX(0);
}

input.notify-custom + label > span {
    color: #fff;
    text-transform: uppercase;
    padding: 8px 8px;
    font-size: .9rem;
    line-height: 1;
    float: left;
}

input.notify-custom:checked + label > span {
    float: right;
}




</style>
